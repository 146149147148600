import React from "react";
import SliderItem1 from '../assets/videos/superyacht.mp4';
import SliderItem2 from '../assets/videos/catamarans.mp4';
import SliderItem3 from '../assets/videos/sailboat.mp4';
import SliderItem4 from '../assets/images/gulet.jpeg';
import whiteArrow from '../assets/images/white_arrow.svg';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

function Slider(){
    return(
    <div className="slider_outer position-relative z-1">
        <h6 className="fw-normal text-uppercase letter-spacing-15 d-block d-md-none" data-aos="fade-up">Yachts</h6>
        <div className="slider_wrapper position-relative">
            <Swiper
                // install Swiper modules
                modules={[Navigation, A11y]}
                freeMode={true}
                spaceBetween={15}
                slidesPerView={3.7}
                navigation={{ nextEl: ".arrow-right", prevEl: ".arrow-left" }}
                loop
                breakpoints={{
                    320: {
                      slidesPerView: 1.6,
                      spaceBetween: 10,
                      centeredSlides:true,
                    },
                    769: {
                        slidesPerView: 3.7,
                        spaceBetween: 15,
                    },
                    1920: {
                      slidesPerView: 3.7,
                      spaceBetween: 15,
                    },
                  }}
                  
                
            >
                <SwiperSlide>
                    <div className="common_slider_slide position-relative">
                        <video width="100%" height="100%" autoPlay muted loop playsInline className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover">
                            <source src={SliderItem1} type="video/mp4" />
                        </video>
                        <div className="common_slider_slide_text position-absolute w-100 h-100 d-flex flex-column justify-content-between z-1">
                            <h6 className="fw-normal text-uppercase letter-spacing-15">Yachts</h6>
                            <h2>Superyachts</h2>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="common_slider_slide  position-relative">
                        <video width="100%" height="100%" autoPlay muted loop playsInline className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover">
                            <source src={SliderItem2} type="video/mp4" />
                        </video>
                        <div className="common_slider_slide_text position-absolute w-100 h-100 d-flex flex-column justify-content-between z-1">
                            <h6 className="fw-normal text-uppercase letter-spacing-15">Yachts</h6>
                            <h2>Catamarans</h2>
                        </div>
                    </div>
                </SwiperSlide>        
                <SwiperSlide>
                    <div className="common_slider_slide  position-relative">
                        <video width="100%" height="100%" autoPlay muted loop playsInline className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover">
                            <source src={SliderItem3} type="video/mp4" />
                        </video>
                        <div className="common_slider_slide_text position-absolute w-100 h-100 d-flex flex-column justify-content-between z-1">
                            <h6 className="fw-normal text-uppercase letter-spacing-15">Yachts</h6>
                            <h2>Sail boats</h2>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="common_slider_slide  position-relative">
                        <img src={SliderItem4} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                        <div className="common_slider_slide_text position-absolute w-100 h-100 d-flex flex-column justify-content-between z-1">
                            <h6 className="fw-normal text-uppercase letter-spacing-15">Yachts</h6>
                            <h2>Gulet boats</h2>
                        </div>
                    </div>
                </SwiperSlide>        
                <SwiperSlide>
                    <div className="common_slider_slide position-relative">
                        <video width="100%" height="100%" autoPlay muted loop playsInline className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover">
                            <source src={SliderItem1} type="video/mp4" />
                        </video>
                        <div className="common_slider_slide_text position-absolute w-100 h-100 d-flex flex-column justify-content-between z-1">
                            <h6 className="fw-normal text-uppercase letter-spacing-15">Yachts</h6>
                            <h2>Superyachts</h2>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="common_slider_slide  position-relative">
                        <video width="100%" height="100%" autoPlay muted loop playsInline className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover">
                            <source src={SliderItem2} type="video/mp4" />
                        </video>
                        <div className="common_slider_slide_text position-absolute w-100 h-100 d-flex flex-column justify-content-between z-1">
                            <h6 className="fw-normal text-uppercase letter-spacing-15">Yachts</h6>
                            <h2>Catamarans</h2>
                        </div>
                    </div>
                </SwiperSlide>        
                <SwiperSlide>
                    <div className="common_slider_slide  position-relative">
                        <video width="100%" height="100%" autoPlay muted loop playsInline className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover">
                            <source src={SliderItem3} type="video/mp4" />
                        </video>
                        <div className="common_slider_slide_text position-absolute w-100 h-100 d-flex flex-column justify-content-between z-1">
                            <h6 className="fw-normal text-uppercase letter-spacing-15">Yachts</h6>
                            <h2>Sail boats</h2>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="common_slider_slide  position-relative">
                        <img src={SliderItem4} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                        <div className="common_slider_slide_text position-absolute w-100 h-100 d-flex flex-column justify-content-between z-1">
                            <h6 className="fw-normal text-uppercase letter-spacing-15">Yachts</h6>
                            <h2>Gulet</h2>
                        </div>
                    </div>
                </SwiperSlide>       
            </Swiper>
            {/* <button className="arrow-left arrow sliderarrow">11</button> */}
            <button className="arrow-right arrow sliderarrow rounded-circle border border-1 border-light position-absolute"><img src={whiteArrow} alt="Image" /></button>
        </div>
    </div>
  );
}
export default Slider; 