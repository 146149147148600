import React from "react";
import AboutBanner from '../components/AboutBanner';
import ContactContent from '../components/ContactContent';
import ContactForm from '../components/ContactForm';



function contactPage() {
  return (
    <div className="aboutpage">
        <AboutBanner />
        <div className="plr-100">
        <ContactContent />
        </div>  
        <ContactForm />    
    </div> 
  );
}

export default contactPage;