import React from "react";
import EnquryBanner from '../components/EnquryBanner';
import EnquryForm from '../components/EnquryForm';



function Enqury() {
  return (
    <div className="App">
      <EnquryBanner />  
      <EnquryForm/>
      

    </div> 
  );
}

export default Enqury;
