import React, { useRef, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import UseSmoothScroll from '../components/UseSmoothScroll';
import Logo from "../assets/images/logo.png";
import MobileMenu from '../components/MobileSideMenu.js';

function Header() {
    const scrollRef = useRef(null);
    UseSmoothScroll(scrollRef);
    const location = useLocation();
    const renderLogo = location.pathname === '/affiliatesignup' || location.pathname === '/about' || location.pathname === '/contact' || location.pathname === '/enqury';
    
    const [isClassAdded, setIsClassAdded] = useState(false);
    const handleClick = () => {
        setIsClassAdded(!isClassAdded);
    };
    const handleCloseMenu = () => {
        setIsClassAdded(false);
    };

    return (
        <>
            <header className={`position-absolute z-2 d-flex align-items-center justify-content-end py-4 py-md-4 px-md-3 w-100 ${isClassAdded ? 'menu-open' : ''}`} ref={scrollRef}>
                {renderLogo && (
                    <Link to='/' className='header_logo'>
                        <img src={Logo} alt="Logo" />
                    </Link>
                )}
                <ul className="d-flex d-md-none justify-content-end align-items-center m-0 rightmenu">
                    <li>
                        <span className="rounded-circle header_plus_button mobilemeubtn d-flex align-items-center justify-content-center" onClick={handleClick}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="8.95001" y1="18" x2="8.95001" stroke="white" />
                                <line y1="8.5" x2="18" y2="8.5" stroke="white" />
                            </svg>
                        </span>
                    </li>
                </ul>

                <ul className="d-none d-md-flex align-items-center m-0 rightmenu">
                    <li><Link to="/affiliatesignup" className="text-uppercase letter-spacing-15 font-12 font-roboto text-light">For Affiliates</Link></li>                    
                    <li><Link to='/about' className="text-uppercase letter-spacing-15 font-12  font-roboto text-light">About</Link></li>
                    <li><Link to='/contact' className="text-uppercase letter-spacing-15 font-12  font-roboto text-light">Contact</Link></li>
                    <li>
                        <Link to='/enqury' className="header_plus_button d-flex align-items-center justify-content-center">
                            <span className='font-12 text-white text-uppercase letter-spacing-15 font-roboto'>Request A Charter</span>
                            <span className='rounded-circle'>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="8.95001" y1="18" x2="8.95001" stroke="white" />
                                <line y1="8.5" x2="18" y2="8.5" stroke="white" />
                            </svg>
                            </span>
                        </Link>
                    </li>
                </ul>
            </header>
            <MobileMenu onClose={handleCloseMenu} />
        </>
    );
}

export default Header;
