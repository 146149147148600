import React from "react";
import { Link, useLocation } from "react-router-dom";

function aboutContent(){
    return(
        <div className="about_content">                
            <p className="pt-1">Meso Travel is a revelation in the Nautical Tourism sector. We provide travel agents, travel influencers, entrepreneurs and travelers access to the largest range of yachts worldwide. We provide our advisors with the ability to earn commissions on every sale.</p>
            <p>Meso Travel’s primary goal is to make yacht chartering an accessible and cost effective option for all travelers, providing guaranteed savings. This has been made possible through a decade of building relationships and partnering with boat owners and leading charter companies across the globe.</p>
            <p>Meso Travel is committed to providing services with integrity, transparency, 24/7 service and a love of ocean travel.</p>  
            <div className="mt-4 mb-4 pt-4 pb-4">
            <hr />
            </div>
            <h4 className="font-22 mb-3">Enquire with Meso</h4>
            <p>Our expert luxury travel team can manage your itinerary with local knowledge and standing for you so you experience the best.</p>
            <ul className="listwithdots d-flex flex-wrap mt-1 pt-3 text-uppercase letter-spacing-15">
                <li>info@mesotravel.com</li>
                <li>+30 694 225 1908</li>
                <li>Ioulianou 28, Athens, 104 34, Porto Rafti, Greece</li>
            </ul>
            <ul className="d-flex align-items-center contact_social mt-3 pt-4">
                <li>
                    <Link to='https://www.instagram.com/mesotravel/?hl=en'>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.00391 4.50781C6.51953 4.50781 4.51562 6.51172 4.51562 8.99609C4.51562 11.4805 6.51953 13.4844 9.00391 13.4844C11.4883 13.4844 13.4922 11.4805 13.4922 8.99609C13.4922 6.51172 11.4883 4.50781 9.00391 4.50781ZM9.00391 11.9141C7.39844 11.9141 6.08594 10.6055 6.08594 8.99609C6.08594 7.38672 7.39453 6.07812 9.00391 6.07812C10.6133 6.07812 11.9219 7.38672 11.9219 8.99609C11.9219 10.6055 10.6094 11.9141 9.00391 11.9141ZM14.7227 4.32422C14.7227 4.90625 14.2539 5.37109 13.6758 5.37109C13.0938 5.37109 12.6289 4.90234 12.6289 4.32422C12.6289 3.74609 13.0977 3.27734 13.6758 3.27734C14.2539 3.27734 14.7227 3.74609 14.7227 4.32422ZM17.6953 5.38672C17.6289 3.98438 17.3086 2.74219 16.2812 1.71875C15.2578 0.695312 14.0156 0.375 12.6133 0.304687C11.168 0.222656 6.83594 0.222656 5.39062 0.304687C3.99219 0.371094 2.75 0.691406 1.72266 1.71484C0.695313 2.73828 0.378906 3.98047 0.308594 5.38281C0.226562 6.82812 0.226562 11.1602 0.308594 12.6055C0.375 14.0078 0.695313 15.25 1.72266 16.2734C2.75 17.2969 3.98828 17.6172 5.39062 17.6875C6.83594 17.7695 11.168 17.7695 12.6133 17.6875C14.0156 17.6211 15.2578 17.3008 16.2812 16.2734C17.3047 15.25 17.625 14.0078 17.6953 12.6055C17.7773 11.1602 17.7773 6.83203 17.6953 5.38672ZM15.8281 14.1562C15.5234 14.9219 14.9336 15.5117 14.1641 15.8203C13.0117 16.2773 10.2773 16.1719 9.00391 16.1719C7.73047 16.1719 4.99219 16.2734 3.84375 15.8203C3.07812 15.5156 2.48828 14.9258 2.17969 14.1562C1.72266 13.0039 1.82813 10.2695 1.82813 8.99609C1.82813 7.72266 1.72656 4.98438 2.17969 3.83594C2.48438 3.07031 3.07422 2.48047 3.84375 2.17187C4.99609 1.71484 7.73047 1.82031 9.00391 1.82031C10.2773 1.82031 13.0156 1.71875 14.1641 2.17187C14.9297 2.47656 15.5195 3.06641 15.8281 3.83594C16.2852 4.98828 16.1797 7.72266 16.1797 8.99609C16.1797 10.2695 16.2852 13.0078 15.8281 14.1562Z" fill="white"/>
                        </svg>
                    </Link>
                </li>
                {/* <li>
                    <Link to='#'>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.6875 10C19.6875 4.64844 15.3516 0.3125 10 0.3125C4.64844 0.3125 0.3125 4.64844 0.3125 10C0.3125 14.8352 3.85508 18.843 8.48633 19.5703V12.8004H6.02539V10H8.48633V7.86562C8.48633 5.43789 9.93164 4.09687 12.1453 4.09687C13.2055 4.09687 14.3141 4.28594 14.3141 4.28594V6.66875H13.0922C11.8891 6.66875 11.5137 7.41562 11.5137 8.18164V10H14.2004L13.7707 12.8004H11.5137V19.5703C16.1449 18.843 19.6875 14.8352 19.6875 10Z" fill="white"/>
                        </svg>
                    </Link>
                </li> */}
                <li>
                    <Link to='https://wa.me/306942251908'>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.1611 3.74121C17.1152 1.69043 14.3906 0.5625 11.4951 0.5625C5.51855 0.5625 0.655273 5.42578 0.655273 11.4023C0.655273 13.3115 1.15332 15.1768 2.10059 16.8223L0.5625 22.4375L6.30957 20.9287C7.8916 21.793 9.67383 22.2471 11.4902 22.2471H11.4951C17.4668 22.2471 22.4375 17.3838 22.4375 11.4072C22.4375 8.51172 21.207 5.79199 19.1611 3.74121ZM11.4951 20.4209C9.87402 20.4209 8.28711 19.9863 6.90527 19.166L6.57812 18.9707L3.16992 19.8643L4.07812 16.5391L3.86328 16.1973C2.95996 14.7617 2.48633 13.1064 2.48633 11.4023C2.48633 6.43652 6.5293 2.39355 11.5 2.39355C13.9072 2.39355 16.168 3.33105 17.8672 5.03516C19.5664 6.73926 20.6113 9 20.6064 11.4072C20.6064 16.3779 16.4609 20.4209 11.4951 20.4209ZM16.4365 13.6729C16.168 13.5361 14.835 12.8818 14.5859 12.7939C14.3369 12.7012 14.1563 12.6572 13.9756 12.9307C13.7949 13.2041 13.2773 13.8096 13.1162 13.9951C12.96 14.1758 12.7988 14.2002 12.5303 14.0635C10.9385 13.2676 9.89355 12.6426 8.84375 10.8408C8.56543 10.3623 9.12207 10.3965 9.63965 9.36133C9.72754 9.18066 9.68359 9.02441 9.61523 8.8877C9.54688 8.75098 9.00488 7.41797 8.78027 6.87598C8.56055 6.34863 8.33594 6.42188 8.16992 6.41211C8.01367 6.40234 7.83301 6.40234 7.65234 6.40234C7.47168 6.40234 7.17871 6.4707 6.92969 6.73926C6.68066 7.0127 5.98242 7.66699 5.98242 9C5.98242 10.333 6.9541 11.6221 7.08594 11.8027C7.22266 11.9834 8.99512 14.7178 11.7148 15.8945C13.4336 16.6367 14.1074 16.7002 14.9668 16.5732C15.4893 16.4951 16.5684 15.9189 16.793 15.2842C17.0176 14.6494 17.0176 14.1074 16.9492 13.9951C16.8857 13.873 16.7051 13.8047 16.4365 13.6729Z" fill="white"/>
                        </svg>
                    </Link>
                </li>
            </ul>
            <p><Link to='/contact' className="btn btn-primary mt-4 mb-5">Contact Us</Link></p>
        </div>
    );
}
export default aboutContent;