import React from "react";
import Icon1 from '../assets/images/service-1.png';
import Icon2 from '../assets/images/service-2.png';
import Icon3 from '../assets/images/service-3.png';



function Services(){    
    return(       
        <div className="services ptb-200">
            <div className="row g-0">
                <div className="col-md-3">
                    <div className="service_leftcol mb-5 mb-md-0" data-aos="fade-up">                    
                        <h6 className="fw-normal text-uppercase letter-spacing-15">Services</h6>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="service_rightcol ps-md-5">
                        <div className="service_rightcol_inner">
                            <div className="common_service_box d-flex pb-md-4 mb-5 pb-sm-4" data-aos="fade-up">
                                <div className="service_icon_box d-flex">
                                    <div className="service_icon d-flex align-items-center justify-content-center border-opacity-25 border border-light rounded-circle">
                                        <img src={Icon1} alt="Image" />
                                    </div>
                                </div>
                                <div className="service_text">
                                    <h4>Yacht charter</h4>
                                    <p>Access to the best boat options and charter deals in the Mediterranean and more.</p>                        
                                </div>
                            </div>
                            <div className="common_service_box d-flex pb-md-4 mb-5 pb-sm-4 mb-sm-3" data-aos="fade-up">
                                <div className="service_icon_box d-flex">
                                    <div className="service_icon d-flex align-items-center justify-content-center border-opacity-25 border border-light rounded-circle">
                                        <img src={Icon2} alt="Image" />
                                    </div>
                                </div>
                                <div className="service_text">
                                    <h4>Luxury concierge</h4>
                                    <p>Our expert luxury travel team can manage your itinerary with local knowledge and standing for you so you experience the best.</p>
                                    <ul className="listwithdots d-flex flex-wrap mt-3 pt-3 text-uppercase letter-spacing-15">
                                        <li>Accomodation</li>
                                        <li>Entertainment</li>
                                        <li>Travel Arrangements</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="common_service_box d-flex" data-aos="fade-up">
                                <div className="service_icon_box d-flex">
                                    <div className="service_icon d-flex align-items-center justify-content-center border-opacity-25 border border-light rounded-circle">
                                        <img src={Icon3} alt="Image" />
                                    </div>
                                </div>
                                <div className="service_text">
                                    <h4>Lifestyle management</h4>
                                    <p>We can provide support for the little details to make sure your trip or extended time at your destination is seamless. </p>                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    );
}
export default Services; 