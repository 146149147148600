import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import FooerBgImage from '../assets/images/footer-background.jpg';


function Footer(){
    const location = useLocation();    
    const renderTopFooter = location.pathname != '/affiliatesignup';

        useEffect(() => {
        if (location.pathname === '/affiliatesignup') {
            window.scrollTo(0, 0);
        }
    }, [location.pathname]);

    
    return(
        <footer className="position-relative pt-5" >
            <img src={FooerBgImage} alt="Bg Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover"/> 
            {renderTopFooter && (                
            <div className="top_footer position-relative pb-5 pt-3 z-2">
                <div className="row g-0">
                    <div className="col-md-4">            
                        <div className="service_leftcol">                    
                            <h6 className="fw-normal text-uppercase letter-spacing-15" data-aos="fade-up">Affiliate Program</h6>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="top_footer_right w-md-75" data-aos="fade-up">
                            <h2 className="mb-4">Are you a travel agent or travel content creator?</h2>
                            <p className="mb-4 pb-3">We specialize in orchestrating bespoke journeys with leading Yacht operators worldwide with commission incentives.</p>
                            <Link to='/affiliatesignup' className="btn btn-primary">Work With Us</Link>
                        </div>
                    </div>    
                </div>
            </div>
            )}
            <div className="bottom_footer border-top border-light position-relative pt-5 pb-5 z-2">
                <div className="row gx-0 ">
                    <div className="col-md-9">
                        <h4 className="pt-2 pt-md-4  mb-3">more information</h4>
                        <p>info@mesotravel.com<br/>+30 694 225 1908</p>
                        
                    </div>
                    <div className="col-md-3">
                        <ul className="d-flex align-items-center contact_social footer_social ps-md-3">
                            <li className="ms-md-auto">
                                <Link to='https://www.instagram.com/mesotravel/?hl=en'>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.00391 4.50781C6.51953 4.50781 4.51562 6.51172 4.51562 8.99609C4.51562 11.4805 6.51953 13.4844 9.00391 13.4844C11.4883 13.4844 13.4922 11.4805 13.4922 8.99609C13.4922 6.51172 11.4883 4.50781 9.00391 4.50781ZM9.00391 11.9141C7.39844 11.9141 6.08594 10.6055 6.08594 8.99609C6.08594 7.38672 7.39453 6.07812 9.00391 6.07812C10.6133 6.07812 11.9219 7.38672 11.9219 8.99609C11.9219 10.6055 10.6094 11.9141 9.00391 11.9141ZM14.7227 4.32422C14.7227 4.90625 14.2539 5.37109 13.6758 5.37109C13.0938 5.37109 12.6289 4.90234 12.6289 4.32422C12.6289 3.74609 13.0977 3.27734 13.6758 3.27734C14.2539 3.27734 14.7227 3.74609 14.7227 4.32422ZM17.6953 5.38672C17.6289 3.98438 17.3086 2.74219 16.2812 1.71875C15.2578 0.695312 14.0156 0.375 12.6133 0.304687C11.168 0.222656 6.83594 0.222656 5.39062 0.304687C3.99219 0.371094 2.75 0.691406 1.72266 1.71484C0.695313 2.73828 0.378906 3.98047 0.308594 5.38281C0.226562 6.82812 0.226562 11.1602 0.308594 12.6055C0.375 14.0078 0.695313 15.25 1.72266 16.2734C2.75 17.2969 3.98828 17.6172 5.39062 17.6875C6.83594 17.7695 11.168 17.7695 12.6133 17.6875C14.0156 17.6211 15.2578 17.3008 16.2812 16.2734C17.3047 15.25 17.625 14.0078 17.6953 12.6055C17.7773 11.1602 17.7773 6.83203 17.6953 5.38672ZM15.8281 14.1562C15.5234 14.9219 14.9336 15.5117 14.1641 15.8203C13.0117 16.2773 10.2773 16.1719 9.00391 16.1719C7.73047 16.1719 4.99219 16.2734 3.84375 15.8203C3.07812 15.5156 2.48828 14.9258 2.17969 14.1562C1.72266 13.0039 1.82813 10.2695 1.82813 8.99609C1.82813 7.72266 1.72656 4.98438 2.17969 3.83594C2.48438 3.07031 3.07422 2.48047 3.84375 2.17187C4.99609 1.71484 7.73047 1.82031 9.00391 1.82031C10.2773 1.82031 13.0156 1.71875 14.1641 2.17187C14.9297 2.47656 15.5195 3.06641 15.8281 3.83594C16.2852 4.98828 16.1797 7.72266 16.1797 8.99609C16.1797 10.2695 16.2852 13.0078 15.8281 14.1562Z" fill="white"/>
                                    </svg>
                                </Link>
                            </li>
                            <li>
                                <Link to='https://wa.me/306942251908'>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.1289 2.79297C13.4922 1.15234 11.3125 0.25 8.99609 0.25C4.21484 0.25 0.324219 4.14062 0.324219 8.92188C0.324219 10.4492 0.722656 11.9414 1.48047 13.2578L0.25 17.75L4.84766 16.543C6.11328 17.2344 7.53906 17.5977 8.99219 17.5977H8.99609C13.7734 17.5977 17.75 13.707 17.75 8.92578C17.75 6.60938 16.7656 4.43359 15.1289 2.79297ZM8.99609 16.1367C7.69922 16.1367 6.42969 15.7891 5.32422 15.1328L5.0625 14.9766L2.33594 15.6914L3.0625 13.0312L2.89062 12.7578C2.16797 11.6094 1.78906 10.2852 1.78906 8.92188C1.78906 4.94922 5.02344 1.71484 9 1.71484C10.9258 1.71484 12.7344 2.46484 14.0938 3.82812C15.4531 5.19141 16.2891 7 16.2852 8.92578C16.2852 12.9023 12.9688 16.1367 8.99609 16.1367ZM12.9492 10.7383C12.7344 10.6289 11.668 10.1055 11.4688 10.0352C11.2695 9.96094 11.125 9.92578 10.9805 10.1445C10.8359 10.3633 10.4219 10.8477 10.293 10.9961C10.168 11.1406 10.0391 11.1602 9.82422 11.0508C8.55078 10.4141 7.71484 9.91406 6.875 8.47266C6.65234 8.08984 7.09766 8.11719 7.51172 7.28906C7.58203 7.14453 7.54687 7.01953 7.49219 6.91016C7.4375 6.80078 7.00391 5.73438 6.82422 5.30078C6.64844 4.87891 6.46875 4.9375 6.33594 4.92969C6.21094 4.92188 6.06641 4.92188 5.92188 4.92188C5.77734 4.92188 5.54297 4.97656 5.34375 5.19141C5.14453 5.41016 4.58594 5.93359 4.58594 7C4.58594 8.06641 5.36328 9.09766 5.46875 9.24219C5.57812 9.38672 6.99609 11.5742 9.17188 12.5156C10.5469 13.1094 11.0859 13.1602 11.7734 13.0586C12.1914 12.9961 13.0547 12.5352 13.2344 12.0273C13.4141 11.5195 13.4141 11.0859 13.3594 10.9961C13.3086 10.8984 13.1641 10.8438 12.9492 10.7383Z" fill="white"/>
                                    </svg>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>
        </footer>
    );
}
export default Footer;