import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import "react-datepicker/dist/react-datepicker.css";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import { post } from "../axios";

function EnquryForm() {
    const [contactDetails, setContactDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: ''
    });
    const [errors, setErrors] = useState({});
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleContactDetailsChange = (e) => {
        const { name, value } = e.target;
        setContactDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const data = {
            first_name: contactDetails.firstName,
            last_name: contactDetails.lastName,
            email: contactDetails.email,
            phone: contactDetails.phone,
            message: contactDetails.message
        };

        try {
            const result = await post("v1/affiliateForm", data, "application/json");
            console.log("API Response:", result);
            setShowSuccessMessage(true);
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("Error submitting form. Please try again.");
        }
    };

    const validateForm = () => {
        const errors = {};

        if (!contactDetails.firstName) {
            errors.firstName = 'Please enter your first name.';
        }
        if (!contactDetails.lastName) {
            errors.lastName = 'Please enter your last name.';
        }
        if (!contactDetails.email) {
            errors.email = 'Please enter your email address.';
        } else if (!isValidEmail(contactDetails.email)) {
            errors.email = 'Please enter a valid email address.';
        }
        if (!contactDetails.phone) {
            errors.phone = 'Please enter your phone number.';
        }
        if (!contactDetails.message) {
            errors.message = 'Please enter your message.';
        }

        return errors;
    };

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    return (
        <div>
            <div className="affiliates_form_wrap pb-5">
                <Form onSubmit={handleSubmit}>
                    <div className="plr-100 mt-4 pt-4 mt-md-5 pt-md-5 pb-5">
                        <div className="affiliates_form_wrap_inner">
                            <Form.Group as={Row} className="mb-4 pb-4 gx-0 ">
                                <Form.Label as="legend" column sm={12} className="mb-3 mb-md-3">General inquiries</Form.Label>
                                <Col sm={12}>
                                    <div className="info_fields d-flex flex-wrap flex-gap-20">
                                        <div>
                                        <Form.Control
                                            type="text"
                                            placeholder="First Name"
                                            name="firstName"
                                            value={contactDetails.firstName}
                                            onChange={handleContactDetailsChange}
                                        />
                                        {errors.firstName && (
                                            <span className="text-danger mt-2 d-block fw-normal">{errors.firstName}</span>
                                        )}
                                        </div>
                                        <div>
                                        <Form.Control
                                            type="text"
                                            placeholder="Last Name"
                                            name="lastName"
                                            value={contactDetails.lastName}
                                            onChange={handleContactDetailsChange}
                                        />
                                        {errors.lastName && (
                                            <span className="text-danger mt-2 d-block fw-normal">{errors.lastName}</span>
                                        )}
                                        </div>
                                        <div>
                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            value={contactDetails.email}
                                            onChange={handleContactDetailsChange}
                                        />
                                        {errors.email && (
                                            <span className="text-danger mt-2 d-block fw-normal">{errors.email}</span>
                                        )}
                                        </div>
                                        <div>
                                        <Form.Control
                                            type="text"
                                            placeholder="Phone"
                                            name="phone"
                                            value={contactDetails.phone}
                                            onChange={handleContactDetailsChange}
                                        />
                                        {errors.phone && (
                                            <span className="text-danger mt-2 d-block fw-normal">{errors.phone}</span>
                                        )}
                                        </div>
                                    </div>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-4 pb-4 gx-0 ">
                                <Form.Label as="legend" column sm={12} className="mb-3 mb-md-3">Leave a message</Form.Label>
                                <Col sm={12}>
                                    <Form.Control
                                        as="textarea"
                                        className="w-100 w-md-75"
                                        rows={6}
                                        name="message"
                                        value={contactDetails.message}
                                        onChange={handleContactDetailsChange}
                                    />
                                    {errors.message && (
                                        <Form.Text className="text-danger mt-2 d-block fw-normal">{errors.message}</Form.Text>
                                    )}
                                </Col>
                            </Form.Group>
                            <Form.Group className="text-end">
                                <Button variant="primary" type="submit">Send</Button>
                                {showSuccessMessage && (
                                    <p className="text-success mt-2">Form submitted successfully!</p>
                                )}
                            </Form.Group>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default EnquryForm;
