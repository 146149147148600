import React, { useState, useEffect, useRef } from 'react';
import Video from '../assets/videos/left-video1.mp4';
import Rightimg1 from '../assets/images/we-strive-image.jpg';
import Rightimg2 from '../assets/images/we-strive-image-2.jpg';
import Rightimg3 from '../assets/images/we-strive-image-3.jpg';
import RightVideo from '../assets/videos/we-strive-video-3.mp4';
import Image1 from '../assets/images/we-strive-new-1.jpg';
import Image2 from '../assets/images/we-strive-new-2.jpg';
import Image3 from '../assets/images/we-strive-new-3.jpg';
import Image4 from '../assets/images/we-strive-new-4.jpg';
import Image5 from '../assets/images/we-strive-new-5.jpg';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

function LeftRightImage() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Set initial state
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const appRef = useRef(); // create a ref for the root level element
  
  


  return (
    <div className="left_right_image pt-40">
      {isMobile ? (
        <div className="row g-0 leftrightmob">
          <div className="col-12">
            <div className="left_right_rightcol_top" data-aos="fade-up">
              <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_72_27)">
                  <path fillRule="evenodd" clipRule="evenodd" d="M62.7003 0.0379107C53.212 0.798804 48.8721 11.7883 55.4529 18.3897C62.3295 25.2875 74.4256 20.9867 74.9824 11.4459C75.363 4.92584 69.5123 -0.508419 62.7003 0.0379107ZM41.4124 0.219483C38.8962 1.18115 37.3153 3.17062 37.33 5.35707C37.3521 8.65852 38.4701 9.96606 42.711 11.6503C46.5554 13.1771 47.6157 14.45 46.9926 16.7906C46.1382 20 42.6208 20.35 38.4129 17.6442C38.0454 17.4079 37.7278 17.2145 37.7071 17.2145C37.6865 17.2145 37.6696 17.8606 37.6696 18.6504V20.0863L38.2947 20.4639C43.6983 23.7286 49.6454 21.2859 49.6427 15.8029C49.6412 12.7093 48.4341 11.3506 44.163 9.63486C41.1591 8.42815 40.2268 7.62273 40.0028 6.04044C39.6759 3.73259 41.6616 2.04835 44.3555 2.3485C45.4641 2.47199 46.3156 2.79247 48.6619 3.96926C48.6964 3.98652 48.7145 3.40994 48.7023 2.68801L48.6801 1.37546L48.1832 1.03555C47.103 0.296595 46.2599 0.110023 43.8 0.0656582C41.9666 0.0325864 41.8883 0.0375886 41.4124 0.219483ZM22.7469 1.20244V2.29187H28.799H34.8511L34.8286 1.22309L34.8061 0.154307L28.7765 0.133656L22.7469 0.112927V1.20244ZM0 0.355802C0 0.526726 10.2744 13.1007 10.4141 13.1007C10.4459 13.1007 12.0489 11.186 13.9762 8.84574C15.9037 6.50554 17.5585 4.52164 17.6536 4.43711L17.8265 4.28344V12.9672V21.651H18.9961H20.1657V10.9631V0.275301H18.893H17.6203L14.123 4.87228C12.1995 7.40058 10.5762 9.51032 10.5156 9.56057C10.3483 9.69939 10.4138 9.77643 6.51829 4.85454L2.89402 0.275301H1.44701C0.538425 0.275301 0 0.305226 0 0.355802ZM65.3997 2.24936C70.9992 3.44374 74.0274 9.41312 71.6294 14.5302C68.8385 20.486 60.467 21.3102 56.5533 16.0146C51.8263 9.61849 57.6275 0.59158 65.3997 2.24936ZM22.7469 10.8825V11.9714H28.7967H34.8464V10.8825V9.79353H28.7967H22.7469V10.8825ZM22.7469 20.562V21.651H28.7967H34.8464V20.562V19.4731H28.7967H22.7469V20.562Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_72_27">
                    <rect width="22" height="26" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p className="font-22 mt-3 pb-4">We strive to design unforgettable experiences on our luxurious yachts, tailored to different budget levels.</p>
            </div>
          </div>
          <div className="col-9" data-aos="fade-right">
            <div className="left_right_leftcol">
              <video width="100%" height="100%" autoPlay muted loop playsInline>
                <source src={Video} type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="col-3" data-aos="fade-left">
            <div className="left_right_rightcol">
              <img src={Rightimg1} alt="Image" className="left_right_rightcol_image1" />
            </div>
          </div>
          <div className="col-12 leftrightbtmimg" data-aos="fade-left">
            <img src={Rightimg2} alt="Image" className='ps-5' />
          </div>
        </div>
      ) : (
        <>
        <div className="westrive_tophead">
          <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_72_27)">
              <path fillRule="evenodd" clipRule="evenodd" d="M62.7003 0.0379107C53.212 0.798804 48.8721 11.7883 55.4529 18.3897C62.3295 25.2875 74.4256 20.9867 74.9824 11.4459C75.363 4.92584 69.5123 -0.508419 62.7003 0.0379107ZM41.4124 0.219483C38.8962 1.18115 37.3153 3.17062 37.33 5.35707C37.3521 8.65852 38.4701 9.96606 42.711 11.6503C46.5554 13.1771 47.6157 14.45 46.9926 16.7906C46.1382 20 42.6208 20.35 38.4129 17.6442C38.0454 17.4079 37.7278 17.2145 37.7071 17.2145C37.6865 17.2145 37.6696 17.8606 37.6696 18.6504V20.0863L38.2947 20.4639C43.6983 23.7286 49.6454 21.2859 49.6427 15.8029C49.6412 12.7093 48.4341 11.3506 44.163 9.63486C41.1591 8.42815 40.2268 7.62273 40.0028 6.04044C39.6759 3.73259 41.6616 2.04835 44.3555 2.3485C45.4641 2.47199 46.3156 2.79247 48.6619 3.96926C48.6964 3.98652 48.7145 3.40994 48.7023 2.68801L48.6801 1.37546L48.1832 1.03555C47.103 0.296595 46.2599 0.110023 43.8 0.0656582C41.9666 0.0325864 41.8883 0.0375886 41.4124 0.219483ZM22.7469 1.20244V2.29187H28.799H34.8511L34.8286 1.22309L34.8061 0.154307L28.7765 0.133656L22.7469 0.112927V1.20244ZM0 0.355802C0 0.526726 10.2744 13.1007 10.4141 13.1007C10.4459 13.1007 12.0489 11.186 13.9762 8.84574C15.9037 6.50554 17.5585 4.52164 17.6536 4.43711L17.8265 4.28344V12.9672V21.651H18.9961H20.1657V10.9631V0.275301H18.893H17.6203L14.123 4.87228C12.1995 7.40058 10.5762 9.51032 10.5156 9.56057C10.3483 9.69939 10.4138 9.77643 6.51829 4.85454L2.89402 0.275301H1.44701C0.538425 0.275301 0 0.305226 0 0.355802ZM65.3997 2.24936C70.9992 3.44374 74.0274 9.41312 71.6294 14.5302C68.8385 20.486 60.467 21.3102 56.5533 16.0146C51.8263 9.61849 57.6275 0.59158 65.3997 2.24936ZM22.7469 10.8825V11.9714H28.7967H34.8464V10.8825V9.79353H28.7967H22.7469V10.8825ZM22.7469 20.562V21.651H28.7967H34.8464V20.562V19.4731H28.7967H22.7469V20.562Z" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_72_27">
                <rect width="22" height="26" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p className="font-22 mt-3 mb-5 pb-4">We strive to design unforgettable experiences on our luxurious yachts, tailored to different budget levels.</p>
        </div>
        <div className="westrive_topimgrow">          
          <div className="westrive_topimgrow_inner">
            <div className="westrive_topimg_col">
              <img src={Image1} alt="Image" />
            </div>
            <div className="westrive_topimg_col">              
              <video width="100%" height="100%" autoPlay muted loop playsInline>
                <source src={Video} type="video/mp4" />
              </video>
            </div>
            <div className="westrive_topimg_col">
              <img src={Image2} alt="Image" />
            </div>
          </div>
        </div>
        <div className="westrive_bottom_imgrow">          
          <div className="westrive_topimgrow_inner">
            <div className="westrive_topimg_col">
              <img src={Image3} alt="Image" />
            </div>
            <div className="westrive_topimg_col">
              <div className=''>
                <img src={Image4} alt="Image" className='left_right_rightcol_animateimage' />
              </div>
            </div>
            <div className="westrive_topimg_col">
              <img src={Image5} alt="Image" />
            </div>
          </div>
        </div>
        </>
      )}
    </div>
  );
}

export default LeftRightImage;
