
import axios from "axios";
import { defaultConfig } from "./utils/config";

const axiosInstance = axios.create({
  baseURL: defaultConfig.Base_URL,
  headers: {
    Accept: "application/json",
  },
});

// axiosInstance.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
// const Token = localStorage.getItem("token");


// const axiosInstance2 = axios.create({
//   baseURL: defaultConfig.Base_URL,
//   headers: {
//     Accept: "application/json",
//     Authorization: Token ? `Bearer ${Token}` : "",
//   },
// });

const makeRequest = async (instance, method, url, data = null, contentType) => {
  try {
    const headers = {
      ...instance.defaults.headers,
      "Content-Type": contentType || instance.defaults.headers["Content-Type"],
    };

    const response = await instance({
      method,
      url,
      data,
      headers,
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
    throw error;
  }
};

export const makeApiRequest = async (method, url, data = null, contentType) => {
  return makeRequest(axiosInstance, method, url, data, contentType);
};

export const get = async (url) => {
  const instance = axiosInstance;
  return makeRequest(instance, "GET", url);
};

export const post = async (url, data, contentType) => {
  const instance = axiosInstance;
  return makeRequest(instance, "POST", url, data, contentType);
};
// export const post2 = async (url, data, contentType) => {
//   const instance = axiosInstance2;
//   return makeRequest(instance, "POST", url, data, contentType);
// };

export const put = async (url, data) => {
  const instance = axiosInstance;
  return makeRequest(instance, "PUT", url, data);
};

export const del = async (url) => {
  const instance = axiosInstance;
  return makeRequest(instance, "DELETE", url);
};