import React, { useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/App.css';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Enqury from './pages/Enqury';
import AffiliateSignUp from './pages/AffiliateSignup';
import About from './pages/About';
import Contact from './pages/Contact';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  AOS.init({      
    duration: 1000,
    once: false, 
  });
  AOS.refresh();  

  return (
    <div className="App" id="myapp">
      <div className='appinner'>
        <Router>
          <Header />          
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/enqury" element={<Enqury />} />
            <Route exact path="/affiliateSignUp" element={<AffiliateSignUp />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/contact" element={<Contact />} />
          </Routes>
          <Footer />
        </Router>      
      </div> 
    </div> 
  );
}

export default App;
