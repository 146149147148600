import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import WhereTravelSlider from '../components/WhereTravelSlider';
import BoatTypeSlider from '../components/BoatTypeSlider';
import { post } from "../axios";

function EnquryForm() {
    const [selectedDate, setSelectedDate] = useState(null);
    const [returnSelectedDate, returnSetSelectedDate] = useState(null);
    const [contactDetails, setContactDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    });
    const [additionalRequirements, setAdditionalRequirements] = useState('');
    const [approximateBudget, setApproximateBudget] = useState('');
    const [travellingPeople, setTravellingPeople] = useState('');
    const [type, setType] = useState('');
    const [boatType, setBoatType] = useState('');
    const [country, setCountry] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false); // State for success message
    const [isActive, setIsActive] = useState(false);
    const [isActiveBoat, setIsActiveBoat] = useState(false);
    const [errors, setErrors] = useState({
        selectedDate: '',
        returnSelectedDate: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        type: '',
        approximateBudget: '',
        travellingPeople: ''
    });

    const handleContactDetailsChange = (e) => {
        const { name, value } = e.target;
        setContactDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value
        }));
        // Clear error message if user starts typing after validation
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const data = {
            contact_details: {
                first_name: contactDetails.firstName,
                last_name: contactDetails.lastName,
                email: contactDetails.email,
                phone: contactDetails.phone
            },
            departure_date_time: selectedDate ? selectedDate.toISOString() : null,
            return_date_time: returnSelectedDate ? returnSelectedDate.toISOString() : null,
            additional_requirements: additionalRequirements,
            approximate_budget: approximateBudget,
            travelling_people: travellingPeople,
            type: type,
            boat_type: boatType,
            country: country
        };

        try {
            const result = await post("v1/submitForm", data, "application/json");
            console.log("API Response:", result);
            setShowSuccessMessage(true); // Show success message
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("Error submitting form. Please try again.");
        }
    };

    const validateForm = () => {
        const errors = {};
        if (!country) {
            errors.country = 'Please select the country.';
        }
        if (!selectedDate) {
            errors.selectedDate = 'Please select a departure date.';
        }
        if (!returnSelectedDate) {
            errors.returnSelectedDate = 'Please select a return date.';
        }
        if (!contactDetails.firstName) {
            errors.firstName = 'Please enter your first name.';
        }
        if (!contactDetails.lastName) {
            errors.lastName = 'Please enter your last name.';
        }
        if (!contactDetails.email) {
            errors.email = 'Please enter your email address.';
        } else if (!isValidEmail(contactDetails.email)) {
            errors.email = 'Please enter a valid email address.';
        }
        if (!contactDetails.phone) {
            errors.phone = 'Please enter your phone number.';
        }
        if (!type) {
            errors.type = 'Please select the option.';
        }
        if (!boatType) {
            errors.boatType = 'Please select the type of boat.';
        }
        if (!approximateBudget) {
            errors.approximateBudget = 'Please select your approximate budget.';
        }
        if (!travellingPeople) {
            errors.travellingPeople = 'Please enter the number of people travelling.';
        }
        if (!additionalRequirements) {
            errors.additionalRequirements = 'Please add additional requirements.';
        }

        return errors;
    };

    const isValidEmail = (email) => {
        // Basic email validation regex
        return /\S+@\S+\.\S+/.test(email);
    };
    const handleSliderClick = (value) => {
        setIsActive(value);
    };
    const handleBoatSliderClick = (value) => {
        setIsActiveBoat(value);
    };

    return (
        <div className="enqury_form">
            <Form onSubmit={handleSubmit}>
                <div className="enqury_form_container plr-100 pt-4" id="destination">
                    <p className="font-12 letter-spacing-15 mb-3 d-block d-md-none text-uppercase">Destination</p>
                    <h4 className="font-26 pb-3 pb-md-4">Where would you like to travel?</h4>
                    <div className="d-flex pb-4 pb-md-5">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="me-2">
                            <circle cx="10" cy="10" r="9.85" stroke="white" stroke-width="0.3" />
                            <circle cx="10" cy="10" r="5.875" fill="white" fill-opacity="0.1" stroke="white" stroke-width="0.25" />
                        </svg>
                        <p className="font-gotham">Click a country to select</p>
                    </div>
                </div>
                <div className={isActive ? "test" : ""}>
                    <WhereTravelSlider
                        setCountry={setCountry}
                        activeClass={isActive}
                        handleSliderClick={handleSliderClick}
                    />
                    {errors.country && (
                        <Form.Text className="text-danger mt-3 d-block fw-normal">{errors.country}</Form.Text>
                    )}

                </div>
                <div className="enqury_form_container plr-100 position-relative pt-5 mt-3">
                    <Form.Group as={Row} className="mb-4 mb-md-5 gx-0">
                        <p className="font-12 letter-spacing-15 mb-3 d-block d-md-none text-uppercase">Dates</p>
                        <Form.Label as="legend" column sm={4} className="pe-md-4 font-26 pb-xs-3 pb-4 pb-md-2">Let us know what dates</Form.Label>
                        <Col sm={8} className="d-flex flex-wrap flex-gap-20">
                            <div className="custom-datepicker d-flex flex-column">
                                <DatePicker
                                    showIcon
                                    toggleCalendarOnIconClick
                                    selected={selectedDate}
                                    onChange={(date) => setSelectedDate(date)}
                                    placeholderText="Depart"
                                    className="bg-transparent shadow-none border-0"
                                />
                                {errors.selectedDate && (
                                    <Form.Text className="text-danger mt-3 d-block fw-normal">{errors.selectedDate}</Form.Text>
                                )}
                            </div>
                            <div className="custom-datepicker d-flex flex-column">
                                <DatePicker
                                    showIcon
                                    toggleCalendarOnIconClick
                                    selected={returnSelectedDate}
                                    onChange={(date) => returnSetSelectedDate(date)}
                                    placeholderText="Return "
                                    className="bg-transparent shadow-none border-0"
                                />
                                {errors.returnSelectedDate && (
                                    <Form.Text className="text-danger mt-3 d-block fw-normal">{errors.returnSelectedDate}</Form.Text>
                                )}
                            </div>
                        </Col>
                    </Form.Group>
                </div>

                <div className="enqury_form_container plr-100 pt-4 pt-md-5" id="boat_type">
                    <p className="font-12 letter-spacing-15 mb-3 d-block d-md-none text-uppercase">Boat Type</p>
                    <h4 className="font-26 pb-3 pb-md-4">What type of boat would you like?</h4>
                    <div className="d-flex pb-4 pb-md-5">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="me-2">
                            <circle cx="10" cy="10" r="9.85" stroke="white" stroke-width="0.3" />
                            <circle cx="10" cy="10" r="5.875" fill="white" fill-opacity="0.1" stroke="white" stroke-width="0.25" />
                        </svg>
                        <p className="font-gotham">Click a boat type to select</p>
                    </div>
                </div>
                <div className={isActive ? "test" : ""}>
                    <BoatTypeSlider
                        setBoatType={setBoatType}
                        activeClass={isActiveBoat}
                        handleSliderClick={handleBoatSliderClick}
                    />
                    <div className="enqury_form_container plr-100 my-4 position-relative">
                        {errors.boatType && (
                            <Form.Text className="text-danger mt-3 d-block fw-normal">{errors.boatType}</Form.Text>
                        )}
                    </div>
                </div>

                <div className="enqury_form_container enqury_normal_fields pt-5 mt-4 plr-100">
                    <Form.Group as={Row} className="mb-3 gx-0 pb-5">
                        <Form.Label as="legend" column sm={12} className="mb-3 mb-md-4 pb-3">Will you be the Captain or need a Skipper or Crewed?</Form.Label>
                        <Col sm={12}>
                            <div className="d-flex flex-wrap flex-gap-20">
                                <Form.Check
                                    type="radio"
                                    label="Bareboat"
                                    name="formHorizontalRadios"
                                    id="formHorizontalRadios1"
                                    className="p-0"
                                    onChange={() => setType('BAREBOAT')}
                                />
                                <Form.Check
                                    type="radio"
                                    label="Skipper"
                                    name="formHorizontalRadios"
                                    id="formHorizontalRadios2"
                                    className="p-0"
                                    onChange={() => setType('SKIPPER')}
                                />
                                <Form.Check
                                    type="radio"
                                    label="Crewed"
                                    name="formHorizontalRadios"
                                    id="formHorizontalRadios3"
                                    className="p-0"
                                    onChange={() => setType('Crewed')}
                                />
                            </div>
                            {errors.type && (
                                <Form.Text className="text-danger mt-3 d-block fw-normal">{errors.type}</Form.Text>
                            )}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-4 pb-4 mb-md-5 pb-md-5 gx-0">
                        <Form.Label as="legend" column sm={4} className="pb-4 pb-md-0 pe-md-4">What is your approximate budget?</Form.Label>
                        <Col sm={8}>
                            <div className="budget_radios d-flex flex-wrap">
                                <Form.Check
                                    type="radio"
                                    label="$1,000 - 5,000"
                                    name="budgetRadios"
                                    id="budgetRadios1"
                                    className="p-0"
                                    onChange={() => setApproximateBudget('$1,000 - 5,000')}
                                />
                                <Form.Check
                                    type="radio"
                                    label="$5,000-$10,000"
                                    name="budgetRadios"
                                    id="budgetRadios3"
                                    className="p-0"
                                    onChange={() => setApproximateBudget('$5,000-$10,000')}
                                />
                                <Form.Check
                                    type="radio"
                                    label="$10,000-$25,000"
                                    name="budgetRadios"
                                    id="budgetRadios4"
                                    className="p-0"
                                    onChange={() => setApproximateBudget('$10,000-$25,000')}
                                />

                                <Form.Check
                                    type="radio"
                                    label="$25,000-$50,000"
                                    name="budgetRadios"
                                    id="budgetRadios5"
                                    className="p-0"
                                    onChange={() => setApproximateBudget('$25,000-$50,000')}
                                />
                                <Form.Check
                                    type="radio"
                                    label="$50,000+"
                                    name="budgetRadios"
                                    id="budgetRadios6"
                                    className="p-0"
                                    onChange={() => setApproximateBudget('$50,000+')}
                                />
                            </div>
                            {errors.approximateBudget && (
                                <Form.Text className="text-danger mt-3 d-block fw-normal">{errors.approximateBudget}</Form.Text>
                            )}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-4 pb-4 mb-md-5 pb-md-5 gx-0">
                        <Form.Label as="legend" column sm={4} className="pb-4 pb-md-0 pe-md-4">How many people are travelling?</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                placeholder=""
                                className="w-50 w-md-25 text-center"
                                onChange={(e) => setTravellingPeople(e.target.value)}
                            />
                            {errors.travellingPeople && (
                                <Form.Text className="text-danger mt-3 d-block fw-normal">{errors.travellingPeople}</Form.Text>
                            )}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-4 pb-4 mb-md-5 pb-md-5 gx-0">
                        <Form.Label as="legend" column sm={4} className="pb-4 pb-md-0 pe-md-4">Additional requirements</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                as="textarea"
                                className="w-100 w-md-75"
                                rows={6}
                                onChange={(e) => setAdditionalRequirements(e.target.value)}
                            />
                            {errors.additionalRequirements && (
                                <Form.Text className="text-danger mt-3 d-block fw-normal">{errors.additionalRequirements}</Form.Text>
                            )}

                        </Col>
                    </Form.Group>
                </div>
                <hr />
                <div className="enqury_form_container plr-100 mt-4 pt-4 mt-md-5 pt-md-5 mb-5 pb-5">
                    <Form.Group as={Row} className="mb-3 pb-3 gx-0">
                        <Form.Label as="legend" column sm={4} className="pb-4 pb-md-0 pe-md-4">Your contact details</Form.Label>
                        <Col sm={8}>
                            <div className="info_fields d-flex flex-wrap flex-gap-20">
                                <div>
                                    <Form.Control
                                        type="text"
                                        placeholder="First Name"
                                        name="firstName"
                                        onChange={handleContactDetailsChange}
                                    />
                                    {errors.firstName && (
                                        <Form.Text className="text-danger mt-3 d-block fw-normal">{errors.firstName}</Form.Text>
                                    )}
                                </div>
                                <div>
                                    <Form.Control
                                        type="text"
                                        placeholder="Last Name"
                                        name="lastName"
                                        onChange={handleContactDetailsChange}
                                    />
                                    {errors.lastName && (
                                        <Form.Text className="text-danger mt-3 d-block fw-normal">{errors.lastName}</Form.Text>
                                    )}
                                </div>
                                <div>
                                    <Form.Control
                                        type="email"
                                        placeholder="Email"
                                        name="email"
                                        onChange={handleContactDetailsChange}
                                    />
                                    {errors.email && (
                                        <Form.Text className="text-danger mt-3 d-block fw-normal">{errors.email}</Form.Text>
                                    )}
                                </div>
                                <div>
                                    <Form.Control
                                        type="text"
                                        placeholder="Phone"
                                        name="phone"
                                        onChange={handleContactDetailsChange}
                                    />
                                    {errors.phone && (
                                        <Form.Text className="text-danger mt-3 d-block fw-normal">{errors.phone}</Form.Text>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-4 pb-4 mb-md-5 pb-md-5 gx-0">
                        <Form.Label as="legend" column sm={4} className="pb-4 pb-md-0 pe-md-4"></Form.Label>
                        <Col sm={8}>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Col>
                    </Form.Group>
                    {showSuccessMessage && (
                        <p className="text-success mt-2 fw-normal font-22">Form submitted successfully!</p>
                    )}
                </div>
            </Form>
        </div>
    );
}

export default EnquryForm;
