import React from "react";
import Bannerimage from '../assets/images/enqury-banner.jpg';
import WhiteDownArrow from '../assets/images/white-down-arrow.svg';



function Homebanner(){
    return(
        <div className="home_banner enqury_banner enqury_banner_new position-relative overlay-15">
            <img src={Bannerimage} alt="Banner Image" className="home_banner_image" />
            <div className="home_banner_content position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-between">
                <div className="home_banner_top text-light">
                    
                </div>
                <div className="enqury_banner_bottom">
                    <div className="row align-items-end">
                        <div className="col-md-9">
                            <div className="home_banner_bottom text-light position-relative z-1">
                                <p className="text-uppercase font-roboto fw-normal letter-spacing-15 font-14 mb-1">Get Started</p>
                                <h1 className="font-32 mb-3 pt-1">Let’s plan your trip</h1>
                                <p className="font-22 pt-1">Let us know a few details and a member of our team will reach out shortly. 
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="enqury_buttons d-flex flex-column align-items-end position-relative z-2">
                                <ul className="text-end d-none d-md-block">
                                    <li><a href="#destination" className="d-inline-block text-uppercase letter-spacing-15 font-14 font-roboto fw-normal text-light bg-white bg-opacity-25 rounded-pill text-center mb-3">Destination</a></li>
                                    <li><a href="#boat_type" className="d-inline-block text-uppercase letter-spacing-15 font-14 font-roboto fw-normal text-light bg-white bg-opacity-25 rounded-pill text-center mb-3">Boat Type</a></li>
                                    <li><a href="#agegroup" className="d-inline-block text-uppercase letter-spacing-15 font-14 font-roboto fw-normal text-light bg-white bg-opacity-25 rounded-pill text-center mb-2">Your Group</a></li>
                                </ul>
                                <img src={WhiteDownArrow} alt="Down Arrow" className="enqury_down_arrow me-4 pe-2" />                                
                            </div>
                        </div>
                    </div>
                </div>                
            </div>             
        </div>
    );
}
export default Homebanner;