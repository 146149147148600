import React from "react";
import SliderItem1 from '../assets/images/where-travel-slide1.jpg';
import SliderItem2 from '../assets/images/where-travel-slide2.jpg';
import SliderItem3 from '../assets/images/where-travel-slide3.jpg';
import SliderItem4 from '../assets/images/where-travel-slide4.jpg';
import SliderItem5 from '../assets/images/where-travel-slide5.jpg';
import SliderItem6 from '../assets/images/where-travel-slide6.jpg';
import whiteArrow from '../assets/images/white_arrow.svg';
import { Navigation, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

function WhereTravelSlider({ setCountry, isActive, onClick, activeClass, handleSliderClick }) {
    const handleSlideChange = (country) => {
        setCountry(country);
    };

    return (
        <div className={`travel_slider_outer common_enqury_slider ${isActive ? 'active' : ''}`} onClick={onClick}>
            <div className="travel_slider_wrapper position-relative mb-3">
                <Swiper
                    watchSlidesProgress
                    modules={[Navigation, A11y]}
                    spaceBetween={4}
                    slidesPerView={3.1}
                    navigation={{ nextEl: ".arrow-right1", prevEl: ".arrow-left1" }}
                    loop
                    className="pb-0"
                    breakpoints={{
                        320: {
                            slidesPerView: 1.2,
                            centeredSlides: true,
                        },
                        769: {
                            slidesPerView: 3.1,
                        },
                        1920: {
                            slidesPerView: 3.1,
                        },
                    }}
                >
                    <SwiperSlide onClick={() => handleSlideChange('Greece')}>
                        <div className={`slider_slide position-relative ${activeClass === "SwiperSlide1" ? "countrySlider" : ""}`} onClick={() => handleSliderClick("SwiperSlide1")}>
                            <img src={SliderItem1} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                            <div className="position-absolute w-100 start-0 bottom-0 p-4 z-1">
                                <h5 className="fw-medium font-22 font-roboto mb-0">Greece</h5>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => handleSlideChange('Croatia')}>
                        <div className={`slider_slide position-relative ${activeClass === "SwiperSlide2" ? "countrySlider" : ""}`} onClick={() => handleSliderClick("SwiperSlide2")}>

                            <img src={SliderItem2} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                            <div className="position-absolute w-100 start-0 bottom-0 p-4 z-1">
                                <h5 className="fw-medium font-22 font-roboto mb-0">Croatia</h5>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => handleSlideChange('Italy')}>
                        <div className={`slider_slide position-relative ${activeClass === "SwiperSlide3" ? "countrySlider" : ""}`} onClick={() => handleSliderClick("SwiperSlide3")}>

                            <img src={SliderItem3} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                            <div className="position-absolute w-100 start-0 bottom-0 p-4 z-1">
                                <h5 className="fw-medium font-22 font-roboto mb-0">Italy</h5>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => handleSlideChange('Turkey')}>
                        <div className={`slider_slide position-relative ${activeClass === "SwiperSlide4" ? "countrySlider" : ""}`} onClick={() => handleSliderClick("SwiperSlide4")}>

                            <img src={SliderItem4} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                            <div className="position-absolute w-100 start-0 bottom-0 p-4 z-1">
                                <h5 className="fw-medium font-22 font-roboto mb-0">Turkey</h5>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => handleSlideChange('Albania')}>
                        <div className={`slider_slide position-relative ${activeClass === "SwiperSlide5" ? "countrySlider" : ""}`} onClick={() => handleSliderClick("SwiperSlide5")}>

                            <img src={SliderItem5} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                            <div className="position-absolute w-100 start-0 bottom-0 p-4 z-1">
                                <h5 className="fw-medium font-22 font-roboto mb-0">Albania</h5>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={() => handleSlideChange('France')}>
                        <div className={`slider_slide position-relative ${activeClass === "SwiperSlide6" ? "countrySlider" : ""}`} onClick={() => handleSliderClick("SwiperSlide6")}>

                            <img src={SliderItem6} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                            <div className="position-absolute w-100 start-0 bottom-0 p-4 z-1">
                                <h5 className="fw-medium font-22 font-roboto mb-0">France</h5>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
                {/* <button className="arrow-left1 arrow sliderarrow enquryslider_arrow rounded-circle border border-1 border-light position-absolute"><img src={whiteArrow} alt="Image" /></button> */}
                <button className="arrow-right1 arrow sliderarrow enquryslider_arrow rounded-circle border border-1 border-light position-absolute"><img src={whiteArrow} alt="Image" /></button>
            </div>
        </div>
    );
}

export default WhereTravelSlider;
