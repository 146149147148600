import React from "react";
import HomeBanner from '../components/HomeBanner';
import LeftRightImage from '../components/LeftRightImage';
import Services from '../components/Services';
import Slider from '../components/Slider';
import Reviews from '../components/Reviews';
import Footer from '../components/Footer';

function App() {
  return (
    <div>
        <HomeBanner />
        <LeftRightImage />
        <Services />
        <Slider />        
        <Reviews/>
    </div> 
  );
}

export default App;
