import React from "react";
import { Link, useLocation } from "react-router-dom";
import BannerVideo from '../assets/videos/mesohomebanner3.mp4';
import logo from '../assets/images/logo.png';



function Homebanner(){
    return(
        <>
        <div className="home_banner main_home_banner  position-relative overlay-15">
            <video width="100%" height="100%" autoPlay muted loop playsInline className="home_banner_image object-fit-cover">
                <source src={BannerVideo} type="video/mp4" />
            </video>           
            <div className="home_banner_content position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-between">
                <div className="home_banner_top text-light">
                    <p className="font-gotham font-22 fw-light mb-3 d-none d-md-block">welcome to</p>
                    <img src={logo} alt="Logo" />
                </div>
                <div className="home_banner_bottom text-light position-relative z-2">
                    <p className="text-uppercase font-roboto fw-normal letter-spacing-15 font-14 mb-1">welcome to</p>
                    <h1 className="font-32 mb-3 pt-1">Attainable luxury</h1>
                    <p className="font-22 pt-1">Exclusive yacht charters are now within reach for more people.</p>
                    <div className="fixed_buttons mt-4 mt-md-0 pt-2 pt-md-0 z-2">
                        <ul>
                            <li><Link to="/affiliatesignup" className="d-block text-uppercase letter-spacing-15 font-14 font-roboto fw-normal text-light bg-white bg-opacity-25 rounded-pill text-center mb-3">Join Meso</Link></li>
                            <li><Link to="/enqury" className="d-block text-uppercase letter-spacing-15 font-14 font-roboto fw-normal text-light bg-white bg-opacity-25 rounded-pill text-center">Charter</Link></li>
                        </ul>
                    </div>
                </div>
            </div>             
        </div>        
        </>
    );
}
export default Homebanner;