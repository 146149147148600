import React from "react";
import AboutBanner from '../components/AboutBanner';
import AboutContent from '../components/AboutContent';
// import ContactForm from '../components/ContactForm';



function aboutPage() {
  return (
    <div className="aboutpage">
        <AboutBanner />
        <div className="plr-100">
        <AboutContent />
        </div>  
        {/* <ContactForm />     */}
    </div> 
  );
}

export default aboutPage;