import React from "react";
import Bannerimage from '../assets/images/freedom-exterior.jpg';
import WhiteDownArrow from '../assets/images/white-down-arrow.svg';



function aboutBanner(){
    return(
        <div className="home_banner affiliate_banner about_banner position-relative overlay-15">
            <img src={Bannerimage} alt="Banner Image" className="home_banner_image" />
            <div className="home_banner_content position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-between">
                <div className="home_banner_top text-light">                    
                </div>
                <div className="enqury_banner_bottom">
                    <div className="row align-items-start">
                        <div className="col-md-9">
                            <div className="about_banner_bottom text-light position-relative z-1">
                                <p className="text-uppercase font-roboto fw-normal letter-spacing-15 font-14 mb-1">About Us</p>
                                <h1 className="font-32 mb-3 pt-1">Meet Meso</h1>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="enqury_buttons d-flex flex-column align-items-end position-relative z-2">
                                <img src={WhiteDownArrow} alt="Down Arrow" className="enqury_down_arrow mt-md-4 mt-md-5 me-0 me-md-4 pe-2" />                                
                            </div>
                        </div>
                    </div>
                </div>                
            </div>             
        </div>
    );
}
export default aboutBanner;