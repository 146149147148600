import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Video from '../assets/videos/reviews-video.mp4';
import Video2 from '../assets/videos/reviews-video2.mp4';
import Video3 from '../assets/videos/reviews-video3.mp4';
import ReviewImage1 from '../assets/images/review-image-1.jpg';
import ReviewImage3 from '../assets/images/review-image-3.jpg';
import ReviewImage4 from '../assets/images/review-image-4.jpg';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

function Reviews() {
  const pinContainerRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Set initial state
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let ctx = gsap.context(() => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: pinContainerRef.current,
          start: "top +=0",
          end: "+=1600",
          scrub: true,
          markers: false,
        }
      });

      tl.to(".common_review_box1", {
        duration: 1,
        x: -200,
        y: 400,
      }, 0);
      tl.to(".common_review_content1", {
        duration: 0.05,
        autoAlpha: 1,
      }, 0.22);
      tl.to(".common_review_content2", {
        duration: 0.005,
        autoAlpha: 1,
      }, 0.30);      
      tl.to(".common_review_content3", {
        duration: 0.1,
        autoAlpha: 1,
      }, 0.5);
      tl.to(".common_review_box3", {
        duration: 1,
        x: 0,
        y: 500,
      }, 0);
      tl.to(".common_review_box4", {
        duration: 1,
        x: 600,
        y: 500,
      }, 0);
      tl.to(".common_review_box5", {
        duration: 1,
        x: 0,
        y: 700,
      }, 0);
    });

    return () => {
      ctx.revert();
    };
  }, []);

  return (
    <div className='reviews_component pb-5 mb-5'>
      {isMobile ? (
        <div className='review_component_mobile'>
          <div className='review_component_head' data-aos="fade-up">
            <p className="ps-md-4 font-16 text-uppercase m-0 pb-2">Reviews</p>          
            <p className="ps-md-4 mb-4 pb-4">from our recent customers</p>
            <h2 className="d-flex mb-5 pb-3">
              <span className="me-2 pe-1">“</span>
              <span>Meso Travel was <br />fantastic.</span>
            </h2>
          </div>
          <div className="common_review_box_mobile common_review_box_mobile1 w-100" data-aos="fade-left">
            <div className="common_review_image_mobile position-relative h-100">
              <video width="100%" height="100%" autoPlay muted loop playsInline  className='object-fit-cover'>
                <source src={Video} type="video/mp4" />
              </video> 
            </div>
            <div className="common_review_content_mobile common_review_content_mobile1" data-aos="fade-up" data-aos-delay="100">
              <h2 className='mb-3'>The company was fantastic</h2>
              <p className='mb-3'>They helped with our original booking and got us a great Catamaran for our vacation. They even followed up after our trip to make sure everything was perfect. I highly recommend Adriana when booking your next vacation.
              </p>
            </div>
          </div>
          <div className='row gx-0 mt-5 pt-4'>
            <div className='col-6'>
              <img src={ReviewImage1} alt="Review Image" className="object-fit-cover" data-aos="fade-right" />
            </div>
            <div className='col-6 ps-4 mt-5'>
              <div className='ps-3 dddd'>
              <video autoPlay muted loop playsInline className='h-100 w-100 object-fit-cover'>
                <source src={Video3} type="video/mp4" />
              </video>
              </div>
            </div>
          </div>
          <div className="common_review_content_mobile common_review_content_mobile2 text-right mb-5 position-relative" data-aos="fade-up" data-aos-delay="100">
            <h2 className='mb-3'>My interaction with Meso</h2>
            <p className='mb-3'>My interaction with Adriana from Meso Travel when bareboat chartering a Yacht in Sicily was exceptional with all queries responded to promptly and fully. My expectations were met and exceeded in what was a very seamless and memorable holiday.
            </p>
          </div>
          <div className='row gx-0'>
            <div className='col-3 common_review_content_mobile4 pb-4'>
              <div className='position-relative common_review_image_mobile h-100'>
              <img src={ReviewImage4} alt="Review Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
              </div>
            </div>
            <div className='col-9 ps-3'>
              <div className='ps-3 common_review_image_mobile common_review_content_mobile5 position-relative overflow-hidden'>
                <video width="100%" height="100%" autoPlay muted loop playsInline  className='position-absolute top-0 start-0 h-100 w-100 object-fit-cover'>
                  <source src={Video2} type="video/mp4" />
                </video> 
              </div>
            </div>
          </div>
          <div className='common_review_content_mobile5_text d-flex flex-column align-items-center justify-content-center' data-aos="fade-up" data-aos-delay="40">
            <div>
            <h2 className="d-flex mb-0 pb-3">
              <span className="me-2 pe-1">“</span>
              <span>Thanks so much Meso</span>
            </h2>
            <p className="ps-md-4 ps-4 mb-4 pb-4">Thanks so much to Adriana.</p>            
            </div>
          </div>
        </div>
      ) : (
        <div className="reviews position-relative pin-container" ref={pinContainerRef}>
          <div className="row gx-0">
            <div className="col-4"></div>
            <div className="col-8">
              <p className="font-14 text-uppercase ps-md-4 mb-4 pb-2">Reviews</p>
              <h2 className="d-flex mb-4">
                <span className="me-2 pe-1">“</span>
                <span>Meso Travel was <br />fantastic.</span>
              </h2>
              <p className="ps-md-4">from our recent customers</p>
            </div>
          </div>
          <div className="reviews_wrap px-3 mt-5 pt-3">
            <div className="row">
              <div className="col-4 d-flex align-items-center">
                <div className="common_review_box common_review_box1 w-100">
                  <div className="common_review_image w-100">
                    <img src={ReviewImage1} alt="Review Image" className="w-100" />
                  </div>
                  <div className="common_review_content"></div>
                </div>
              </div>
              <div className="col-8 reviews_right">
                <div className="row">
                  <div className="col-7">
                    <div className="common_review_box common_review_box2 w-100 h-100">
                      <div className="common_review_image position-relative h-100">
                        <video width="100%" height="100%" autoPlay muted loop playsInline className='position-absolute top-0 start-0 h-100 w-100 object-fit-cover'>
                            <source src={Video} type="video/mp4" />
                        </video>                        
                      </div>
                      <div className="common_review_content common_review_content1 p-4">
                        <h2 className='mb-3'>The company was fantastic</h2>
                        <p className='mb-3'>They helped with our original booking and got us a great Catamaran for our vacation. They even followed up after our trip to make sure everything was perfect. I highly recommend Adriana when booking your next vacation.
                        </p>
                        <Link to='#' className='text-light text-uppercase font-14 font-roboto letter-spacing-15'>View Review</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row py-4">
                  <div className="col-5 position-relative">
                    <div className="common_review_box common_review_box3 w-100 h-100">
                      <div className="common_review_image position-relative h-100">
                        <img src={ReviewImage3} alt="Review Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                      </div>
                      <div className="common_review_content"></div>
                    </div>
                    <div className="common_review_content common_review_content2 p-4">
                      <h2 className='mb-3'>My interaction with Meso</h2>
                      <p className='mb-3'>My interaction with Adriana from Meso Travel when bareboat chartering a Yacht in Sicily was exceptional with all queries responded to promptly and fully. My expectations were met and exceeded in what was a very seamless and memorable holiday.</p>
                      <Link to='#' className='text-light text-uppercase font-14 font-roboto letter-spacing-15'>View Review</Link>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="common_review_box common_review_box4 w-100 h-100">
                      <div className="common_review_image position-relative h-100">
                        <img src={ReviewImage4} alt="Review Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                      </div>
                      <div className="common_review_content"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-8 position-relative">
                    <div className="common_review_box common_review_box5 w-100 h-100">
                      <div className="common_review_image position-relative h-100">
                        <video width="100%" height="100%" autoPlay muted loop playsInline className='position-absolute top-0 start-0 h-100 w-100 object-fit-cover'>
                          <source src={Video2} type="video/mp4" />
                        </video> 
                      </div>
                      <div className="common_review_content common_review_content3 p-4">
                        <h2 className='mb-3'>“ Thanks so much Meso</h2>
                        <p className='mb-3'>Thanks so much to Adriana.</p>
                        <Link to='#' className='text-light text-uppercase font-14 font-roboto letter-spacing-15'>View Review</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      
    </div>
  );
}

export default Reviews;
