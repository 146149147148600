import React from "react";
import SliderItem1 from '../assets/images/boat-type-1.jpg';
import SliderItem2 from '../assets/images/boat-type-2.jpg';
import SliderItem3 from '../assets/images/boat-type-3.jpg';
import SliderItem4 from '../assets/images/boat-type-4.jpg';
import whiteArrow from '../assets/images/white_arrow.svg';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

function boatTypeSlider({setBoatType ,activeClass, handleSliderClick}){
    const handleSlideChange = (boatType) => {
        setBoatType(boatType);
    };
    return(
    <div className="boat_type_slider_outer common_enqury_slider">
        <div className="boat_type_slider_wrapper position-relative mb-3">
            <Swiper                 
                // install Swiper modules
                watchSlidesProgress
                modules={[Navigation, A11y]}
                spaceBetween={9}
                slidesPerView={3.4}
                navigation={{ nextEl: ".arrow-right2", prevEl: ".arrow-left2" }}
                loop
                //centeredSlides={true}
                //pagination={{ clickable: true }}
                className="pb-0"
                breakpoints={{
                    320: {
                        slidesPerView: 1.2,
                        centeredSlides:true,
                    },
                    769: {
                        slidesPerView: 3.4,
                    },
                    1920: {
                        slidesPerView: 3.4,
                    },
                }}
            >
                    <SwiperSlide onClick={() => handleSlideChange('Motor Yacht')}>
                    <div className={`slider_slide position-relative ${activeClass === "Swiper1" ? "boatSlider" : ""}`} onClick={() => handleSliderClick("Swiper1")}>
                        <img src={SliderItem1} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                        <div className="position-absolute w-100 start-0 bottom-0 p-4 z-1">
                            <h5 className="fw-medium font-18 font-roboto mb-0">Motor Yacht</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide onClick={() => handleSlideChange('Catamaran')}>
                <div className={`slider_slide position-relative ${activeClass === "Swiper2" ? "boatSlider" : ""}`} onClick={() => handleSliderClick("Swiper2")}>
                <img src={SliderItem2} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                        <div className="position-absolute w-100 start-0 bottom-0 p-4 z-1">
                        <h5 className="fw-medium font-18 font-roboto mb-0">Catamaran</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide onClick={() => handleSlideChange('Sailboat')}>
                <div className={`slider_slide position-relative ${activeClass === "Swiper3" ? "boatSlider" : ""}`} onClick={() => handleSliderClick("Swiper3")}>
                <img src={SliderItem3} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                        <div className="position-absolute w-100 start-0 bottom-0 p-4 z-1">
                        <h5 className="fw-medium font-18 font-roboto mb-0">Sailboat</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide onClick={() => handleSlideChange('Gulet')}>
                <div className={`slider_slide position-relative ${activeClass === "Swiper4" ? "boatSlider" : ""}`} onClick={() => handleSliderClick("Swiper4")}>
                <img src={SliderItem4} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                        <div className="position-absolute w-100 start-0 bottom-0 p-4 z-1">
                        <h5 className="fw-medium font-18 font-roboto mb-0">Gulet</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide onClick={() => handleSlideChange('Motor Yacht')}>
                <div className={`slider_slide position-relative ${activeClass === "Swiper5" ? "boatSlider" : ""}`} onClick={() => handleSliderClick("Swiper5")}>
                <img src={SliderItem1} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                        <div className="position-absolute w-100 start-0 bottom-0 p-4 z-1">
                            <h5 className="fw-medium font-18 font-roboto mb-0">Motor Yacht</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide onClick={() => handleSlideChange('Catamaran')}>
                <div className={`slider_slide position-relative ${activeClass === "Swiper6" ? "boatSlider" : ""}`} onClick={() => handleSliderClick("Swiper6")}>
                <img src={SliderItem2} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                        <div className="position-absolute w-100 start-0 bottom-0 p-4 z-1">
                        <h5 className="fw-medium font-18 font-roboto mb-0">Catamaran</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide onClick={() => handleSlideChange('Sailboat')}>
                <div className={`slider_slide position-relative ${activeClass === "Swiper7" ? "boatSlider" : ""}`} onClick={() => handleSliderClick("Swiper7")}>
                <img src={SliderItem3} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                        <div className="position-absolute w-100 start-0 bottom-0 p-4 z-1">
                        <h5 className="fw-medium font-18 font-roboto mb-0">Sailboat</h5>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide onClick={() => handleSlideChange('Gulet')}>
                <div className={`slider_slide position-relative ${activeClass === "Swiper8" ? "boatSlider" : ""}`} onClick={() => handleSliderClick("Swiper8")}>
                <img src={SliderItem4} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                        <div className="position-absolute w-100 start-0 bottom-0 p-4 z-1">
                        <h5 className="fw-medium font-18 font-roboto mb-0">Gulet</h5>
                        </div>
                    </div>
                </SwiperSlide>                    
            </Swiper>
            {/* <button className="arrow-left2 arrow sliderarrow enquryslider_arrow rounded-circle border border-1 border-light position-absolute"><img src={whiteArrow} alt="Image" /></button> */}
            <button className="arrow-right2 arrow sliderarrow enquryslider_arrow rounded-circle border border-1 border-light position-absolute"><img src={whiteArrow} alt="Image" /></button>
        </div>
    </div>
  );
}
export default boatTypeSlider; 