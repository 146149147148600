import React from "react";
import AffiliateSignupBanner from '../components/AffiliateSignupBanner';
import AffiliateForm from '../components/AffiliateForm';



function affiliateSignUp() {
  return (
    <div className="affiliate_signup">
      <AffiliateSignupBanner />  
      <AffiliateForm />    
    </div> 
  );
}

export default affiliateSignUp;