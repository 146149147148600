import React from 'react';
import { Link } from "react-router-dom";

function MobileMenu({ onClose }) {
    return (
        <>  
            <div className="mobile_side_menu position-fixed z-3">
                <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" className='menuclose position-absolute' onClick={onClose}>
                    <rect width="45.4558" height="45.4558" rx="22.7279" fill="white" fill-opacity="0.2"/>
                    <line x1="29.0566" y1="29.1272" x2="16.3287" y2="16.3993" stroke="white"/>
                    <line x1="16.0107" y1="28.7385" x2="28.7386" y2="16.0106" stroke="white"/>
                </svg>

                <ul className="d-flex flex-column align-items-start m-0 rightmenu">
                    <li className='mb-4'>
                        <Link to='/enqury' className="header_plus_button font-12 font-roboto text-white text-uppercase letter-spacing-15" onClick={onClose}>
                            Request A Charter                            
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="8.95001" y1="18" x2="8.95001" stroke="white" />
                                <line y1="8.5" x2="18" y2="8.5" stroke="white" />
                            </svg>
                        </Link>
                    </li>
                    <li className='mb-4'><Link to="/affiliatesignup" className="text-uppercase letter-spacing-15 font-12 font-roboto text-light" onClick={onClose}>For Affiliates</Link></li>                    
                    <li className='mb-4'><Link to='/about' className="text-uppercase letter-spacing-15 font-12 font-roboto text-light" onClick={onClose}>About</Link></li>
                    <li><Link to='/contact' className="text-uppercase letter-spacing-15 font-12 font-roboto text-light" onClick={onClose}>Contact</Link></li>                    
                </ul>
            </div>
        </>
    );
}

export default MobileMenu;
